const API_URL = 'https://api.zstudy.co/academia';
// const API_URL = 'http://127.0.0.1:6002/academia';

exports.SIGNIN_URL = `${API_URL}/staff-sign-in`;
exports.GET_GENERAL_REPORT_URL = `${API_URL}/general-report`;
exports.GET_COLLECTION_DATA = `${API_URL}/collection-data`;
exports.SEARCH_COLLECTION_URL = `${API_URL}/search-collection`;
exports.COURSE_URL = `${API_URL}/course`;
exports.ACADEMIA_URL = `${API_URL}/academia`;
exports.GRADE_URL = `${API_URL}/academia-grade`;
exports.STUDENT_URL = `${API_URL}/student-pupil`;
exports.ACADEMIC_URL = `${API_URL}/academic-subject`;
exports.GET_ACADEMIA_URL = (id) => `${API_URL}/academia/${id}`;
exports.GET_ACADEMIC_URL = (id) => `${API_URL}/academic/${id}`;
exports.ACADEMIC_COPY_COURSE_URL = `${API_URL}/academic-copy-course`;

exports.GET_SUBSCRIPTION_REPORT_URL = `${API_URL}/subscription-report`;
exports.GET_ORDER_REPORT_URL = `${API_URL}/order-report`;
exports.PUSH_NOTIFICATION = `${API_URL}/push-notification`;
exports.ACTION_NOTIFICATION = `${API_URL}/action-notification`;
exports.GET_SUBSCRIPTION_DATA = `${API_URL}/get-subscription-data`;
exports.ACTION_GENRE = `${API_URL}/action-genre`;
exports.ACTION_ADMIN_URL = `${API_URL}/action-admin`;
exports.PARENT_URL = `${API_URL}/parent`;
exports.ACTION_ARTIST_URL = `${API_URL}/action-artist`;
exports.ACTION_ALBUM_URL = `${API_URL}/action-album`;
exports.EXAM_REPORT_URL = `${API_URL}/exam-report`;
exports.ACTION_AUDIO_URL = `${API_URL}/action-audio`;
exports.TEACHER_URL = `${API_URL}/teacher`;
exports.GET_DISK_USAGE_URL = `${API_URL}/get-disk-usage`;
exports.DELETE_ADMIN_URL = `${API_URL}/delete-admin`;
exports.ADD_ADMIN_USER_URL = `${API_URL}/add-admin-user`;
exports.EDIT_ADMIN_USER_URL = `${API_URL}/edit-admin-user`;
exports.GET_DATA_LIST = `${API_URL}/data-list`;
exports.GET_DATA_CUSTOM_LIST = `${API_URL}/data-custom-list`;
exports.GET_ARTIST_DATA_LIST = `${API_URL}/artist-data-list`;
exports.GET_GENRE_URL = `${API_URL}/get-genre`;
exports.GET_FILE_URL = `${API_URL}/get-file`;
exports.GET_SIMULATOR_URL = `${API_URL}/simulator`;
exports.GET_ALBUM_URL = `${API_URL}/get-album`;
exports.GET_ALL_TRACKS_URL = `${API_URL}/get-tracks`;
exports.GET_COURSE_VIDEOS_URL = (id) => `${API_URL}/get-course-video/${id}`;
exports.GET_COURSE_URL = (id) => `${API_URL}/course/${id}`;
exports.GET_STUDENT_URL = (id) => `${API_URL}/student/${id}`;
exports.SEARCH_ARTIST_URL = `${API_URL}/search-artist`;
exports.ACTION_TRACK_URL = `${API_URL}/action-track`;
exports.ACTION_VIDEO_URL = `${API_URL}/action-video`;
exports.ACTION_COURSE_URL = `${API_URL}/action-course`;
exports.ACTION_ACADEMIC_URL = `${API_URL}/action-academic`;
exports.ACTION_APP_URL = `${API_URL}/action-app`;
exports.ACTION_CATEGORY_URL = `${API_URL}/action-category`;
exports.ACTION_PRODUCT_URL = `${API_URL}/action-product`;
exports.ACTION_BOOK_URL = `${API_URL}/action-book`;
exports.GET_CATEGORY_URL = `${API_URL}/get-category`;
